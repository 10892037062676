import "./ButtonList.css";
import Header from "../Header/Header.jsx";
import ButtonCard from "../ButtonCard/ButtonCard.jsx";
import ButtonPopup from "../ButtonPopup/ButtonPopup.jsx";
import { Helmet } from "react-helmet";
import { useState, useMemo, useEffect } from "react";

const ButtonList = ({ buttons, handleCreateButton }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);

  // Initialize selectedCategory from local storage or default to "ae"
  const [selectedCategory, setSelectedCategory] = useState(
    localStorage.getItem("selectedCategory") || "ae"
  );

  // Update local storage whenever selectedCategory changes
  useEffect(() => {
    localStorage.setItem("selectedCategory", selectedCategory);
  }, [selectedCategory]);

  const handleShowPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const filteredAndSortedButtons = useMemo(() => {
    const filtered = selectedCategory
      ? buttons.filter((button) => button.category === selectedCategory)
      : buttons;

    return filtered.slice().sort((a, b) => a.name.localeCompare(b.name));
  }, [buttons, selectedCategory]);

  return (
    <>
      <Helmet>
        <meta property="og:title" content="AE2 Extension" />
        <meta
          property="og:description"
          content="AE2 Extension for After Effects Features Overview"
        />
        <meta property="og:image" content="%PUBLIC_URL%/preview.jpg" />
        <meta property="og:url" content="https://guides.ae2authors.net" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header></Header>
      <section className="buttons-card">
        {/* Кнопки категорий */}
        <div className="buttons-nav">
          {["ae", "pp", "mg", "am", "dr", "gt"].map((category) => (
            <div
              key={category}
              className={`buttons-link ${
                selectedCategory === category ? "active" : ""
              }`}
              onClick={() => handleCategorySelect(category)}
            >
              {category.toUpperCase()}
            </div>
          ))}
        </div>

        {filteredAndSortedButtons.length ? (
          <ul className="buttons-card__list">
            {filteredAndSortedButtons.map((data) => (
              <ButtonCard key={data._id} button={data} />
            ))}
          </ul>
        ) : null}
        {isPopupVisible && (
          <ButtonPopup
            onClose={handleClosePopup}
            handleCreateButton={handleCreateButton}
          />
        )}
      </section>
    </>
  );
};

export default ButtonList;
